import React from "react";
import { createContext, useContext, useEffect, useState } from "react"
import { dictionaryApi } from "../api/uf_dictionary";

const TranslateContext = createContext({});


const TranslateProvider = (props) => {
    const [dictionary, set_dictionary] = useState();
    useEffect(() => {
        reload_dictionary();
    },[])
    const reload_dictionary = async () => {
        const res = await dictionaryApi.select_all();
        if (res.is_success) {
            let obj = {};
            res.data.forEach(x => {
                obj[x.vi] = x.en;
            });
            // console.log("dictionary", obj)
            set_dictionary(obj)
        }
    }
    const translate = (vi) => {
        if (localStorage.language === "us") {
            if (dictionary[vi]) return dictionary[vi]
        }
        return vi;
    }
    return (
        <TranslateContext.Provider value={{ translate }} {...props} />
    );
}
const useTranslate = () => useContext(TranslateContext);

export { TranslateProvider, useTranslate }
import React from 'react'

export const LoaderPage = () => {


    return (
        <>
            <div style={{
                height: window.innerHeight - 100,
                display: "flex",
                alignItems: "center",
                width: "100%",
                background: "transparent",
                flexDirection: "column",
                justifyContent: "center"

            }}>
                <img width="60px" src={"./images/dual_ring.svg"} alt="loader" style={{
                    borderRadius: "50%"
                }} />
                {/* <LoadPanel visible={true} /> */}
            </div>
        </>
    )
}

export default LoaderPage
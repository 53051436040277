// import defaultUser from '../utils/default-user';
import appInfo from '../app-info';
import { call_post_api } from './api_helper';
import axios from 'axios';
export async function signIn(username, password) {
  try {
    const res = await axios.post(
      appInfo.api_url + '/login',
      Object.assign({}, { username: username }, { password: password })
    );
    return res.data;
  } catch (error) {
    return {
      is_success: false,
      data: null
    }
  }
}

export async function signInSSO(ticket_key, hash) {
  try {
    const res = await axios.post(
      appInfo.api_url + '/login',
      Object.assign({}, { ticket_key: ticket_key }, { hash: hash })
    );
    return res.data;
  } catch (error) {
    return {
      is_success: false,
      data: null
    }
  }

}


export async function getUser() {
  return call_post_api(appInfo.api_url + '/login/detail');

};


export async function signOut(token) {
  return call_post_api(
    appInfo.home_url + '/api/logout',
    token
  );

};
